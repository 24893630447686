import React, { useState } from 'react';
import { Input, Button, FormItem, FormContainer, toast, Notification } from 'components/ui';
import { Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { config } from 'utils/apiEndPoint';
import axiosInstance from 'services/_authHttp';
import { ActionLink } from 'components/shared';

const validationSchema = Yup.object().shape({
    mobile: Yup.string()
        .required('Please enter your mobile number')
        .matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
});

const otpValidationSchema = Yup.object().shape({
    otp: Yup.string()
        .required('Please enter the OTP')
        .matches(/^\d{6}$/, 'OTP must be 6 digits'),
    new_password: Yup.string()
        .required('Please enter a new password')
        .min(6, 'Password must be at least 6 characters'),
});

const ForgotPasswordForm = ({ className, signInUrl = '/sign-in' }) => {
    const navigate = useNavigate();
    const [otpDialogOpen, setOtpDialogOpen] = useState(false);
    const [otpLoading, setOtpLoading] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');

    const sendOtp = async (mobile) => {
        try {
            await axiosInstance.post(config?.ForgotPasswordotp.requestsendOtp, {
                phone_number: mobile,
            });
            toast.push(
                <Notification 
                    closable 
                    type="success" 
                    duration={6000}
                >
                    OTP Sent Successfully
                </Notification>
            );
            setOtpDialogOpen(true);
            setMobileNumber(mobile);
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'Failed to send OTP';
            toast.push(
                <Notification 
                    closable 
                    type="danger" 
                    duration={6000} 
                >
                    {errorMessage}
                </Notification>
            );
        }
    }

    const verifyOtpAndResetPassword = async (values) => {
        setOtpLoading(true);
        try {
            await axiosInstance.post(config?.ForgotPasswordotp.resetverifyOtp, {
                phone_number: mobileNumber,
                otp: values.otp,
                new_password: values.new_password,
            });
            toast.push(
                <Notification 
                    closable 
                    type="success" 
                    duration={6000}
                >
                    Password Reset Successfully
                </Notification>
            );
            setOtpDialogOpen(false);
            navigate(signInUrl);
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'Failed to reset password';
            toast.push(
                <Notification 
                    closable 
                    type="danger" 
                    duration={6000} 
                >
                    {errorMessage}
                </Notification>
            );
        } finally {
            setOtpLoading(false);
        }
    }

    const OtpDialog = () => {
        const lastFourDigits = mobileNumber.slice(-4); // Get the last four digits of the mobile number
        return (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                    <h2 className="text-2xl font-bold text-center mb-4">
                        Verify OTP & Reset Password
                    </h2>
                    <p className="text-center mb-4">
                        A verification code has been sent to your mobile number ending in <strong>{lastFourDigits}</strong>.
                    </p>
                    <Formik
                        initialValues={{ otp: '', new_password: '' }}
                        validationSchema={otpValidationSchema}
                        onSubmit={(values) => verifyOtpAndResetPassword(values)}
                    >
                        {({ touched, errors, isSubmitting }) => (
                            <Form>
                                <FormContainer>
                                    <FormItem
                                        invalid={errors.otp && touched.otp}
                                        errorMessage={errors.otp}
                                    >
                                        <Field
                                            type="text"
                                            name="otp"
                                            placeholder="Enter OTP"
                                            component={Input}
                                        />
                                    </FormItem>
                                    <FormItem
                                        invalid={errors.new_password && touched.new_password}
                                        errorMessage={errors.new_password}
                                    >
                                        <Field
                                            type="password"
                                            name="new_password"
                                            placeholder="Enter New Password"
                                            component={Input}
                                        />
                                    </FormItem>
                                    <Button
                                        type="submit"
                                        variant="solid"
                                        className="w-full"
                                        loading={isSubmitting || otpLoading}
                                    >
                                        Reset Password
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="outline"
                                        className="w-full mt-4"
                                        onClick={() => setOtpDialogOpen(false)}
                                    >
                                        Cancel
                                    </Button>
                                </FormContainer>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className={className}>
                <div className="mb-6">
                    <h3 className="mb-1">Forgot Password</h3>
                    <p>
                        Please enter your mobile number to receive an OTP for verification
                    </p>
                </div>
                <Formik
                    initialValues={{
                        mobile: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(true);
                        await sendOtp(values.mobile);
                        setSubmitting(false);
                    }}
                >
                    {({ touched, errors, isSubmitting }) => (
                        <Form>
                            <FormContainer>
                                <FormItem
                                    invalid={errors.mobile && touched.mobile}
                                    errorMessage={errors.mobile}
                                >
                                    <Field
                                        type="text"
                                        autoComplete="off"
                                        name="mobile"
                                        placeholder="Mobile Number"
                                        maxLength={10}
                                        component={Input}
                                    />
                                </FormItem>
                                <Button
                                    block
                                    loading={isSubmitting}
                                    variant="solid"
                                    type="submit"
                                >
                                    Send OTP
                                </Button>
                            </FormContainer>
                        </Form>
                    )}
                </Formik>
                {otpDialogOpen && <OtpDialog />}
            </div>
            <div className="text-center mt-4">
                <span>Back to  </span>
                <ActionLink to={signInUrl}>Sign In</ActionLink>
            </div>
        </>
    );
}

export default ForgotPasswordForm;
