// import Dashboard from 'views/Shipments/SalesDashboard/components/Dashboard'

import ForgotPassword from "views/auth/ForgotPassword"
import PickupReq from "views/Shipments/PickupRequest/components/PickupReq"

// const BASE_URL = process.env.REACT_PUBLIC_BASE_URL;
const BASE_URL = 'https://api.shipcluescargo.com'

export const config = {
    // Onboarding
    authentication: {
        signIn: `${BASE_URL}/auth/login/`,
        signUp: `${BASE_URL}/auth/register/`,
        refreshToken: `${BASE_URL}/auth/refresh-token/`,
    },
    // mobile otp
    otp: {
        sendOtp: `${BASE_URL}/auth/send-otp/`,
        verifyOtp: `${BASE_URL}/auth/verify-otp/`,
    },
    // Forgot Password mobile otp
    ForgotPasswordotp: {
        requestsendOtp: `${BASE_URL}/auth/request-password-reset/`,
        resetverifyOtp: `${BASE_URL}/auth/verify-password-reset-otp/`,
    },
    // Wallet   
    recharge: {
        createOrder: `${BASE_URL}/transactions/create/order/shipcargo/`,
        verifyPayment: `${BASE_URL}/transactions/shipcargo/verify/payment/`,
        transactionhistory: `${BASE_URL}/wallets/transactions`,
    },
    // Admin 

    admin:{
        sellerlist: `${BASE_URL}/shipcargo/sellers/shipclues_admin/`
    },

    // Dashboard
    dashboard: {
        Shipments: `${BASE_URL}/shipcargo/shipments/api/status-count/`,
        shipmentcount: `${BASE_URL}/shipcargo/shipments/dashboard/count-by-day/`,

    },
    // Warehouse page
    warehouse: {
        fetchWarehouse: `${BASE_URL}/warehouse/get_by_seller/`,
        addWarehouse: `${BASE_URL}/warehouse/create_warehouse/`,
    },
    // Billing  page
    ratecard: {
        vasapi: `${BASE_URL}/pricing/get-ratecard/`,
    },


    // Tools api endpoint here 

    ratecal:{
        ratecalculator: `${BASE_URL}/serviceability/shipcargo/rates/`,
        misreport: `${BASE_URL}/shipcargo/shipments/mis_report/`
    },


    // setup and manage page
    kyc: {
        iskyccheck: `${BASE_URL}/shipcargo/sellers/check-kyc-status/`,
        companydetails: `${BASE_URL}/shipcargo/sellers/kyc/company-details/`,
        businessdetails: `${BASE_URL}/shipcargo/sellers/kyc/business-details/`,
        bankaccountdetails: `${BASE_URL}/shipcargo/sellers/kyc/bank-details/`,
    },

    // kyc get api
    kycget: {
        compadetailsget: `${BASE_URL}/shipcargo/sellers/get-company-details/`,
        businessdetailsget: `${BASE_URL}/shipcargo/sellers/get-business-details/`,
        bankdetaisget: `${BASE_URL}/shipcargo/sellers/get-bank-details/`,
        checkkycstatus: `${BASE_URL}/shipcargo/sellers/check-kyc-status/`

    },

    // kyc:> Courier Preference page api here

    courierget: {
        courierpreference: `${BASE_URL}/shipclues/partners/list_of_partners`,
    },

    // Create shipment all page api here
    shipment: {
        create: `${BASE_URL}/shipcargo/shipments/create-shipment/`,
        courierRates: `${BASE_URL}/serviceability/courier-rates/`,
        fullfill: `${BASE_URL}/shipcargo/shipments/fullfill/`,
        remarks: `${BASE_URL}/shipcargo/shipments/receiver/`,
        update: `${BASE_URL}/shipcargo/shipments/update/value`,
        printlabale: `${BASE_URL}/shipcargo/shipments/shipment-label/160/?format=pdf`,
        allshipment: `${BASE_URL}/shipcargo/shipments/status/list/`,
        editShipment: `${BASE_URL}/shipcargo/shipments/api/`, //for single shipment details feching using shipment id
        pendingpickup: `${BASE_URL}/shipcargo/shipments/pickup_pending`,
        pickupschedule: `${BASE_URL}/shipcargo/shipments/get_all/status_details/?status=pickup_scheduled`,
        intransit: `${BASE_URL}/shipcargo/shipments/get_all/status_details/?status=in_transit`,
        rto: `${BASE_URL}/shipcargo/shipments/get_all/status_details/?status=rto`,
        delivered: `${BASE_URL}/shipcargo/shipments/get_all/status_details/?status=delivered`,
        allshipments: `${BASE_URL}/shipcargo/shipments/all_status_shipments`,
        shipmentpreviews: `${BASE_URL}/shipcargo/shipments/api/`,
    },

    // Pickup Request api here 

    pickuprequest:{
        pickuprequestget:`${BASE_URL}/sellers/get-company-details/`
    },

    imageUpload: `${BASE_URL}/shipcargo/shipments/upload-image/`,
}
