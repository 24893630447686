import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { useNavigate } from 'react-router-dom'
import appConfig from 'configs/app.config'
import { logIn, createNewUser } from 'services/authentication'
import {
    onSignInSuccess,
    onSignOutSuccess,
    onSignInError,
} from 'store/auth/sessionSlice'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import useQuery from './useQuery'

function useAuth() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const query = useQuery()
    const { token, signedIn, error } = useSelector(
        (state) => state.auth.session
    )

    const signIn = async (values) => {
        try {
            const resp = await logIn(values)

            const {
                access: accessToken,
                first_name,
                last_name,
                email,
                user: username,
            } = resp.data

            localStorage.setItem('accessToken', accessToken)

            if (accessToken) {
                dispatch(onSignInSuccess({ accessToken }))
                dispatch(
                    setUser(resp.data)
                )

                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )

                return {
                    status: 'success',
                    message: 'Welcome to shipcargo',
                }
            }
        } catch (error) {
            const errorMessage =
                error?.response?.data?.message ||
                'Incorrect login details. Please Verify and retry.'
            const errorType =
                error?.response?.data?.error ||
                'The password you entered is incorrect.'

            dispatch(onSignInError({ message: errorMessage, type: errorType }))

            return {
                status: 'failed',
                message: errorMessage,
                errorType,
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await createNewUser(values)
            if (resp.data) {
                const {
                    token,
                    first_name,
                    last_name,
                    email,
                    user: username,
                } = resp.data
                dispatch(onSignInSuccess(token))
                dispatch(
                    setUser(resp?.data)
                )
                

                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )

                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        // await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
        error,
    }
}

export default useAuth
