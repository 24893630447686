import React from 'react'
import ForgotPasswordForm from './ForgotPasswordForm'

const ForgotPassword = () => {
    // return <ForgotPasswordForm disableSubmit={false} />
    return <ForgotPasswordForm  />

}

export default ForgotPassword
